@import "../../styles/variables.scss";

.root {
	width: 100%;
}

.scroller {
	display: flex;
	align-items: center;
	height: 100%;
}

.flexContainer {
	height: 100%;
	width: 100%;
	border-bottom: 1px solid $light_dark-faded-color;
	#{$dark-theme} & {
		border-bottom: 1px solid $dark_light-faded-color;
	}
}

.indicator {
	background-color: $light_main-color;
	border-radius: 18px;
	height: 3px;
	#{$dark-theme} & {
		background-color: $dark_regular-text-color;
	}
}
