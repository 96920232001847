@import "styles/variables.scss";

.root {
	font-size: inherit;
	color: $light_main-color;
	text-decoration: none;
	#{$dark-theme} & {
		color: $dark_regular-text-color;
	}

	&[data-underline="always"] {
		text-decoration: underline;
	}
	&[data-underline="hover"] {
		&:hover {
			text-decoration: underline;
		}
	}
	&[data-block="true"] {
		color: unset;
		display: block;
	}
}
