@import "styles/variables.scss";

.bottomActions {
	bottom: -$select-subject-decription-height;

	.actionsWrapper {
		width: 100%;
		padding: 0;
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: $max-width) {
			padding: 0 8px;
		}

		& > * {
			width: calc(50% - 4px) !important;
		}
	}
}

.a {
	color: $light_main-color;
	text-decoration: underline;
	cursor: pointer;
	#{$dark-theme} & {
		color: $dark_regular-text-color;
	}
}
