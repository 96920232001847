@import "styles/variables.scss";

.variant {
	color: fade-out($light_regular-text-color, 0.12);
	background-color: $light_dark-faded-color;
	#{$dark-theme} & {
		color: fade-out($dark_regular-text-color, 0.12);
		background-color: $dark_light-faded-color;
	}
	&[data-state="right"] {
		color: rgb(255, 255, 255);
		background-color: $positive-color;
		#{$dark-theme} & {
			background-color: $positive-color;
		}
	}
	&[data-state="wrong"] {
		color: rgb(255, 255, 255);
		background-color: $negative-color;
		#{$dark-theme} & {
			background-color: $negative-color;
		}
	}
	&[data-state="faded"] {
		opacity: 0.18;
	}
}
