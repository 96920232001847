@import "styles/variables.scss";

.list {
	padding: 0;
	color: $light_main-color;
	#{$dark-theme} & {
		color: $dark_main-color;
	}

	.listItemPrimaryText {
		font-size: 1em;
		font-weight: 500;
		color: $light_main-color;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
		}
	}

	.listItemSecondaryText {
		color: $light_placeholder-color;
		#{$dark-theme} & {
			color: $dark_placeholder-color;
		}
	}

	.listItemIcon {
		color: $light_main-color;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
		}
	}

	.listDivider {
		margin-left: 72px;
		width: calc(100% - 72px);
		background-color: $light_dark-faded-color;
		opacity: 0.3;
		#{$dark-theme} & {
			opacity: 1;
			background-color: $dark_light-faded-color;
		}
	}
}

.typography {
	color: $light_regular-text-color;
	#{$dark-theme} & {
		color: $dark_regular-text-color;
	}
}

.graphIcon {
	margin-top: 26px;
	align-self: flex-start;
}

.highlight {
	font-weight: 500;
	color: $light_main-color;
	#{$dark-theme} & {
		color: $dark_regular-text-color;
	}
}

.graph {
	position: relative;
	width: 100%;
	height: 220px;
	padding: 16px;
	user-select: none;
}

.tooltip {
	background-color: $light_block-color;
	border-radius: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 10px;
	border: 1px solid $light_dark-faded-color;
	#{$dark-theme} & {
		border: 1px solid $dark_light-faded-color;
		background-color: $dark_block-color;
	}

	.total {
		font-size: 14px;
		color: $light_main-color;
		text-align: center;
		line-height: 1em;
		margin-bottom: 4px;
		font-weight: 500;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
		}
	}

	.date {
		font-size: 12px;
		color: $light_placeholder-color;
		text-align: center;
		line-height: 1em;
		#{$dark-theme} & {
			color: $dark_placeholder-color;
		}
	}
}

.proxiesList {
	display: block;
	margin-top: 6px;
}

.redColor {
	color: $negative-color;
}
