@import "styles/variables.scss";

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: rgb(50, 50, 50);
	padding: 16px 44px 16px 16px;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: $max-width;
	margin: 0 auto;
	width: 100%;
	overflow: hidden;
	border-radius: 18px;
	&[data-type="success"] {
		background-color: #0fb682;
	}
	&[data-type="error"] {
		background-color: $negative-color;
	}
	&[data-type="warning"] {
		background-color: rgb(236, 134, 0);
	}

	.closeIcon {
		position: absolute;
		right: 10px;
		top: 10px;
		color: $dark_regular-text-color;
		width: 24px;
		height: 24px;
		cursor: pointer;
	}

	.title {
		font-size: 16px;
		color: $dark_regular-text-color;
		font-weight: 500;
		margin-bottom: 4px;
	}

	.message {
		font-size: 16px;
		color: $dark_regular-text-color;

		.messageIcon {
			color: inherit;
			display: inline;
			width: 1em;
			height: 1em;
			margin-right: 8px;
			vertical-align: top;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}
