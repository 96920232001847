@import "styles/variables.scss";

.root {
	position: relative;
	overflow: hidden;
	background-color: $light_block-color;
	border: 1px solid $light_dark-faded-color;
	box-shadow: none;
	#{$dark-theme} & {
		border: 1px solid $dark_light-faded-color;
		background-color: $dark_block-color;
	}
}
