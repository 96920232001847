@import "styles/variables.scss";

.root {
	z-index: 1302 !important;
	backdrop-filter: blur(15px);

	.paper {
		width: calc($max-width - 28px);
		max-width: 100%;
		margin: 14px;
		background-color: $light_block-color;
		transform: translate(0); //fix overflow
		#{$dark-theme} & {
			background-color: $dark_block-color;
		}

		/*.close {
			position: absolute;
			top: 6px;
			right: 6px;
			width: 24px;
			height: 24px;
			padding: 5px;
			cursor: pointer;
			flex: none;
			z-index: 9;
			color: $light_placeholder-color;
			#{$dark-theme} & {
				color: $dark_placeholder-color;
			}

			svg {
				width: 26px;
				height: 26px;
			}
		}*/

		.title {
			color: $light_main-color;
			#{$dark-theme} & {
				color: $dark_regular-text-color;
			}
		}

		.text {
			color: $light_regular-text-color;
			#{$dark-theme} & {
				color: $dark_regular-text-color;
			}
		}

		.action {
			//overflow: hidden;
			//border-bottom-right-radius: 40px;
		}
	}
}
