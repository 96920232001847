/* This stylesheet generated by Transfonter (https://transfonter.org) on August 2, 2017 10:14 PM */

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-MediumItalic.eot');
	src: local('SF UI Text Medium Italic'), local('SFUIText-MediumItalic'),
		url('./SFUIText-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-MediumItalic.woff') format('woff'),
		url('./SFUIText-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-Semibold.eot');
	src: local('SF UI Text Semibold'), local('SFUIText-Semibold'),
		url('./SFUIText-Semibold.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-Semibold.woff') format('woff'),
		url('./SFUIText-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-Regular.eot');
	src: local('SF UI Text Regular'), local('SFUIText-Regular'),
		url('./SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-Regular.woff') format('woff'),
		url('./SFUIText-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-Bold.eot');
	src: local('SF UI Text Bold'), local('SFUIText-Bold'),
		url('./SFUIText-Bold.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-Bold.woff') format('woff'),
		url('./SFUIText-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-Light.eot');
	src: local('SF UI Text Light'), local('SFUIText-Light'),
		url('./SFUIText-Light.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-Light.woff') format('woff'),
		url('./SFUIText-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-Medium.eot');
	src: local('SF UI Text Medium'), local('SFUIText-Medium'),
		url('./SFUIText-Medium.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-Medium.woff') format('woff'),
		url('./SFUIText-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Text';
	src: url('./SFUIText-SemiboldItalic.eot');
	src: local('SF UI Text Semibold Italic'), local('SFUIText-SemiboldItalic'),
		url('./SFUIText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
		url('./SFUIText-SemiboldItalic.woff') format('woff'),
		url('./SFUIText-SemiboldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}
