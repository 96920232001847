@import "styles/variables.scss";

.variant {
	color: fade-out($light_regular-text-color, 0.12);
	background-color: $light_dark-faded-color;
	#{$dark-theme} & {
		color: fade-out($dark_regular-text-color, 0.12);
		background-color: $dark_light-faded-color;
	}
	&[data-active="true"] {
		color: $light_block-color;
		background-color: $light_main-color;
		#{$dark-theme} & {
			color: $dark_block-color;
			background-color: $dark_regular-text-color;
		}
	}
	&[data-state="right"] {
		color: rgb(255, 255, 255);
		background-color: $positive-color;

		#{$dark-theme} & {
			color: $dark_regular-text-color;
			background-color: $positive-color;
		}
	}
	&[data-state="wrong"] {
		color: $dark_regular-text-color;
		background-color: $negative-color;

		#{$dark-theme} & {
			color: $dark_regular-text-color;
			background-color: $negative-color;
		}
	}
	&[data-state="warn"] {
		color: $dark_regular-text-color;
		background-color: #ff9800;

		#{$dark-theme} & {
			color: $dark_regular-text-color;
			background-color: #ff9800;
		}
	}
	&[data-state="faded"] {
		opacity: 0.5;
	}

	.variantIcon {
		margin-bottom: -2px;
		margin-right: 6px;
		vertical-align: bottom;
		display: inline;
	}
}
