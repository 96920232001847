@import "../../styles/variables.scss";

.root {
	padding: 0 10px;
	//margin-right: 10px;
	text-transform: none;
	//min-width: unset;
	//max-width: unset;
	// height: 44px;
}
.textColorPrimary {
	color: $light_lightgray-color;
	font-size: 16px;
	font-weight: 400;
	/*#{$dark-theme} & {
		color: $dark_regular-text-color;
	}*/
}
.selected {
	color: $light_main-color;
	font-weight: 500;
	#{$dark-theme} & {
		color: $dark_regular-text-color;
	}
}
