$max-width: 500px;
$font-family: "SF UI Text", "Roboto", "Helvetica", "Arial", sans-serif;
$transition: all 0.5s cubic-bezier(0.25, 0.8, 0.05, 1);

$header-height: 56px;
$bottom-navigation-height: 56px;
$select-subject-decription-height: 54px;

$positive-color: #4cc151;
$negative-color: #f44336;

$light_main-color: #5e0fec;
$light_background-color: #fefeff;
$light_block-color: rgb(255, 255, 255);
$light_placeholder-color: rgba(0, 0, 0, 0.5);
$light_regular-text-color: rgba(0, 0, 0, 0.87);
$light_dark-faded-color: rgba(0, 0, 0, 0.08);
$light_lightgray-color: #b1b1b2;

$dark_main-color: #242526;
$dark_background-color: #18191a;
$dark_block-color: #242526;
$dark_placeholder-color: rgba(255, 255, 255, 0.5);
$dark_regular-text-color: rgb(252, 252, 252);
$dark_light-faded-color: rgba(255, 255, 255, 0.08);
$dark_lightgray-color: #5e5e5f;

$dark-theme: "body[data-theme='dark']";

/*$xl: 1920px - 1px;
$lg: 1280px - 1px;
$md: 960px - 1px;
$sm: 600px - 1px;
$xs: 480px - 1px;*/
