@import "styles/variables.scss";

.listItemAvatar {
	min-width: 42px;

	.avatar {
		font-size: 16px;
		letter-spacing: -0.05px;
		background-color: $light_placeholder-color;
		#{$dark-theme} & {
			background-color: $dark_placeholder-color;
			color: $dark_block-color;
		}
		&[data-primary="true"] {
			background-color: rgba(255, 207, 0, 0.19) !important;
			border: 1px solid $light_dark-faded-color;
		}
	}
}

.user {
	display: flex;
	justify-content: space-between;
	white-space: nowrap;
	flex-wrap: nowrap;

	.name {
		max-width: 80%;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.total {
		font-weight: 600;
	}
}

.listDivider {
	margin-left: 58px;
	width: calc(100% - 72px);
	background-color: $light_dark-faded-color;
	opacity: 0.3;
	#{$dark-theme} & {
		opacity: 1;
		background-color: $dark_light-faded-color;
	}
}

.listItem {
	&[data-me="true"] {
		background-color: $light_dark-faded-color;
		#{$dark-theme} & {
			background-color: $dark_light-faded-color;
		}
	}
}
