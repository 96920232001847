@import "../../styles/variables.scss";

.wrapper {
	display: flex;

	.root {
		position: relative;
		min-height: 34px;
		border-radius: 16px;
		padding: 0 12px;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		transition: $transition;
		background-color: transparentize($light_dark-faded-color, 0.03);
		#{$dark-theme} & {
			background-color: $dark_light-faded-color;
		}
		&[data-fullwidth="true"] {
			flex-grow: 1;
		}
		&[data-active="true"] {
			background-color: $light_main-color;
			#{$dark-theme} & {
				background-color: $dark_regular-text-color;
			}

			%infoBlock {
				background-color: $dark_light-faded-color;
			}

			.divider {
				background-color: $dark_regular-text-color;
				opacity: 0.1;
				#{$dark-theme} & {
					background-color: $dark_main-color;
				}
			}

			.name {
				color: $light_block-color;
				#{$dark-theme} & {
					color: $dark_main-color;
				}
			}
		}

		.newBadgeIcon {
			position: absolute;
			color: #ff3131;
			height: 24px;
			width: 24px;
			z-index: 9;
			left: -5px;
			top: -9px;
		}

		.recentBadge {
			position: absolute;
			width: 18px;
			height: 18px;
			background-color: $light_main-color;
			border-radius: 50%;
			z-index: 9;
			left: -3px;
			top: -4px;
			padding: 2px 2px 2px 1px;
			#{$dark-theme} & {
				background-color: $dark_regular-text-color;
			}

			.recentBadgeIcon {
				position: relative;
				vertical-align: top;
				width: 100%;
				height: 100%;
				color: #fff;
				#{$dark-theme} & {
					color: $dark_main-color;
				}
			}
		}

		.name {
			position: relative;
			margin: 12px 0 8px;
			line-height: 1.1em;
			font-weight: 400;
			width: 100%;
			color: fade-out($light_regular-text-color, 0.12);
			#{$dark-theme} & {
				color: fade-out($dark_regular-text-color, 0.4);
			}
		}

		.divider {
			background-color: $light_dark-faded-color;
			opacity: 0.4;
			#{$dark-theme} & {
				background-color: fade-out($dark_regular-text-color, 0.7);
			}
		}

		.info {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			overflow: hidden;
			margin: 8px 0;

			%infoBlock {
				position: relative;
				flex: none;
				border-radius: 99px;
				height: 24px;
				background-color: fade-out($light_main-color, 0.1);
				margin: 3px 8px 3px 0;
				align-self: flex-start;
				#{$dark-theme} & {
					background-color: $dark_main-color;
				}
			}
			.text {
				@extend %infoBlock;
				padding: 0 8px;
				font-size: 12px;
				line-height: 24px;
				font-weight: 500;
				color: #fff;
			}
			.languageFlag {
				@extend %infoBlock;

				img {
					position: relative;
					height: 100%;
					border-radius: 99px;
					padding: 5px;
					min-width: 30px;
				}
			}
		}
	}

	.actions {
		display: flex;
		flex: none;
		flex-direction: column;
		justify-content: center;
		margin-left: 10px;

		.button {
			margin: 2px 0;
			width: 32px;
			height: 32px;
			flex: none;
			color: fade-out($light_regular-text-color, 0.3);
			background-color: transparentize($light_dark-faded-color, 0.03);
			#{$dark-theme} & {
				color: fade-out($dark_regular-text-color, 0.4);
				background-color: $dark_light-faded-color;
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}
