@import "styles/variables.scss";

.root {
	.progress {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		top: 0;
		margin: auto;
		color: lightgray;
	}
}
