@import "styles/variables.scss";

$margin-bottom: 6px;

.root {
	position: fixed;
	width: 100%;
	bottom: 0;
	max-width: $max-width;
	display: flex;
	flex-direction: column;
	align-items: center;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 10;
	user-select: none;
	transition: $transition;

	&[data-overscrolled="true"] {
		transform: translateY(
			-$select-subject-decription-height - $margin-bottom
		);
	}
	&[data-overscrolled="false"] {
		transform: translateY(
			-$bottom-navigation-height - $select-subject-decription-height - $margin-bottom
		);
	}
}
