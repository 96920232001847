@import "styles/variables.scss";

.root {
	position: fixed;
	background-color: $light_background-color;
	width: 100%;
	height: 100%;
	z-index: 99999;
	top: 0;
	left: 0;
	#{$dark-theme} & {
		background-color: $dark_background-color;
	}

	.icon {
		position: absolute;
		width: 62px;
		height: 62px;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		#{$dark-theme} & {
			opacity: 0.3;
		}
	}
}
