@import "src/styles/variables.scss";

.root {
	position: relative;

	.content {
		position: relative;
		width: 100%;
		min-height: 100vh;
		max-width: $max-width;
		margin: 0 auto;
		//overflow: hidden;
		padding: (8px + $header-height) 0
			(
				8px + $bottom-navigation-height +
					$select-subject-decription-height
			)
			0;
		@media screen and (max-width: $max-width) {
			padding: (12px + $header-height) 8px
				(
					12px + $bottom-navigation-height +
						$select-subject-decription-height
				);
		}
	}
}
