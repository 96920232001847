@import "styles/variables.scss";

.root {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 32px 0;
	&[data-message-first="true"] {
		flex-direction: column-reverse;

		.message {
			margin-bottom: 6px;
		}
		%icon {
			margin-bottom: 0;
		}
	}

	%icon {
		position: relative;
		height: 44px;
		width: 44px;
		margin-bottom: 6px;
		color: $light_lightgray-color;
		#{$dark-theme} & {
			color: $dark_lightgray-color;
		}
	}

	.icon {
		@extend %icon;
	}
	.message {
		position: relative;
		text-align: center;
		width: 60%;
		max-width: 240px;
		font-size: 1em;
		font-weight: 500;
		line-height: 1.2em;
		color: $light_lightgray-color;
		#{$dark-theme} & {
			color: $dark_lightgray-color;
		}
	}
	.progress {
		@extend %icon;
		padding: 4px;
	}
}
