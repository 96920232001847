@import "styles/variables.scss";

.root {
	height: 36px;
	padding: 0 18px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: inherit;
	text-transform: uppercase;
	overflow: hidden;
	user-select: none;
	white-space: nowrap;
	border-radius: 18px;
	outline: none;
	transition: $transition;
	&[disabled] {
		opacity: 0.6;
		filter: saturate(0.9) contrast(0.6);
		pointer-events: none;
	}
	&[data-bigger] {
		height: 46px;
		border-radius: 23px;
		font-size: 16px;
	}
	&[data-wide] {
		height: 56px;
		width: 100%;
		border-radius: 0;
		font-weight: 600;
	}
	&[data-fullwidth] {
		width: 100%;
	}
	&[data-loading="true"] {
		pointer-events: none;

		.preloader {
			opacity: 1;
		}
		.iconBefore,
		.iconAfter,
		.label {
			opacity: 0;
		}
	}

	&[data-variant="primary"] {
		background-color: $light_main-color;
		color: #ffffff;
		#{$dark-theme} & {
			background-color: $dark_regular-text-color;
			color: $dark_main-color;
		}
	}
	&[data-variant="primary"] .preloader {
		color: #fff;
		#{$dark-theme} & {
			color: $dark_main-color;
		}
	}

	&[data-variant="white"] {
		background-color: #ffffff;
		color: $light_regular-text-color;
	}
	&[data-variant="white"] .preloader {
		color: $light_main-color;
		#{$dark-theme} & {
			color: $light_regular-text-color;
		}
	}

	&[data-variant="line-primary"] {
		background-color: transparent;
		color: $light_main-color;
	}
	&[data-variant="line-primary"]:not([disabled]):hover {
		background-color: $light_main-color;
		color: #fff;
	}

	&[data-variant="faded"] {
		background-color: $light_dark-faded-color;
		color: $light_regular-text-color;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
			background-color: $dark_light-faded-color;
		}
	}
	/*&[data-variant="faded"]:not([disabled]):active {
		box-shadow: inset 0 0 0 99px rgba(0, 0, 0, 0.1);
	}*/

	&[data-variant="negative"] {
		background-color: $negative-color;
		color: #ffffff;
		//box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
	}
	&[data-variant="positive"] {
		background-color: $positive-color;
		color: #ffffff;
		//box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
	}
	/*&[data-variant="negative"]:not([disabled]):active {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	}*/

	@mixin icon {
		position: relative;
		font-size: inherit;
		width: 1.2em;
		height: 1.2em;
		flex: none;
		color: inherit;
	}
	.iconBefore {
		@include icon;
		margin-right: 6px;
	}
	.iconAfter {
		@include icon;
		margin-left: 6px;
	}

	.preloader {
		position: absolute;
		height: 20px;
		width: 20px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		color: inherit;
		transition: $transition;
		opacity: 0;
	}
	.label {
		color: inherit;

		&[data-pulse="true"] {
			animation: pulse 2s ease-in-out infinite;
		}
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.4;
	}
	100% {
		opacity: 1;
	}
}
