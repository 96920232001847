@import "styles/variables.scss";

.root {
	position: relative;
	width: 100%;
	vertical-align: top;
	border-radius: 10px;
	box-shadow: inset 0 0 0 1px $light_dark-faded-color;
	overflow: hidden;
	display: flex;
	align-items: center;
	transition: $transition;
	#{$dark-theme} & {
		box-shadow: inset 0 0 0 1px $dark_light-faded-color;
	}

	&[data-focused="true"] {
		box-shadow: inset 0 0 0 2px fade-out($light_main-color, 0.9);
		#{$dark-theme} & {
			box-shadow: inset 0 0 0 2px fade-out($dark_regular-text-color, 0.8);
		}
	}

	.icon {
		position: relative;
		width: 24px;
		height: 24px;
		margin: 0 12px;
		flex: none;
		color: fade-out($light_placeholder-color, 0.05);
		transition: $transition;
		z-index: 9;
		#{$dark-theme} & {
			color: fade-out($dark_placeholder-color, 0.05);
		}
	}

	&[data-focused="true"] .icon {
		color: $light_main-color;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
		}
	}

	.Input {
		background-color: transparent;
		color: $light_regular-text-color;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
		}
		&:after {
			content: unset;
		}
		&:before {
			content: unset;
		}

		.input {
			&:-internal-autofill-previewed,
			&:-internal-autofill-selected,
			&:-webkit-autofill::first-line,
			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus,
			&:-webkit-autofill:active {
				-webkit-text-fill-color: $light_regular-text-color !important;
				font-family: $font-family !important;
				-webkit-background-clip: text;
				#{$dark-theme} & {
					-webkit-text-fill-color: $dark_regular-text-color !important;
				}
			}

			&[type="number"] {
				-moz-appearance: textfield;
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				&::outer-spin-button,
				&::inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
	}

	.label {
		white-space: nowrap;
		color: $light_placeholder-color;
		#{$dark-theme} & {
			color: $dark_placeholder-color;
		}
	}
}
