@import "styles/variables.scss";

.container {
	width: 100%;
	overflow: hidden;
}

.percent {
	margin-top: 6px;
	font-weight: 600;
	font-size: 18px;
	display: inline-block;
	padding: 4px 12px;
	background-color: $light_main-color;
	color: $dark_regular-text-color;
	border-radius: 99px;
}

.titleLogo {
	display: inline-block;
	width: 1em;
	height: 1.5em;
	margin-right: 4px;
	vertical-align: top;
}

.catWrapper {
	position: relative;
	width: 100%;
	height: 220px;
	margin: 10px 0;
	overflow: hidden;

	.catImage {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 12px;
		vertical-align: top;
		background-color: $light_dark-faded-color;
		#{$dark-theme} & {
			background-color: $dark_light-faded-color;
		}
	}

	.catText {
		position: absolute;
		font-family: "Impact", $font-family;
		width: 100%;
		bottom: 5%;
		color: white;
		text-align: center;
		font-weight: bold;
		letter-spacing: -0.5px;
		font-size: 23px;
		text-transform: uppercase;
		text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000,
			0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000,
			0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000,
			0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
		z-index: 1;
	}
}
