@import "styles/variables.scss";

.root {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	margin: 0 auto;
	backdrop-filter: blur(20px);
	background-color: $light_main-color;
	transition: $transition;
	z-index: 10;
	user-select: none;
	max-width: $max-width;
	overflow: hidden;
	border-radius: 0 0 18px 18px;
	@media screen and (max-width: $max-width) {
		border-radius: 0;
	}
	#{$dark-theme} & {
		border-bottom: 1px solid $dark_light-faded-color;
		background-color: fade-out($dark_main-color, 0.3);
	}
	&[data-disable-blur="true"] {
		backdrop-filter: none;
		#{$dark-theme} & {
			background-color: $dark_main-color;
		}
	}
	&[data-overscrolled="true"] {
		box-shadow: none;
		transform: translateY(-56px);
	}
	&[data-overscrolled="false"] {
		transform: translateY(0px);
	}

	.container {
		height: $header-height;
		min-height: $header-height;
		max-width: $max-width;
		margin: 0 auto;
		width: 100%;
		padding: 0 16px;
		display: flex;
		align-items: center;

		.title {
			color: white;
			flex-grow: 1;
		}

		.linkBack {
			position: relative;
			width: 48px;
			height: 100%;
			margin-left: -16px;
			display: flex;
			align-items: center;
			justify-content: center;

			.iconBack {
				position: relative;
				flex: none;
				color: #fff;
				width: 28px;
				height: 28px;
			}
		}

		.icon {
			color: #fff;
		}

		.searchIcon {
			flex: none;
			color: rgba(255, 255, 255, 0.6);
			margin-right: 8px;
		}

		.inputRoot {
			position: relative;
			width: 100%;
			height: 100%;

			.inputInput {
				position: relative;
				width: 100%;
				height: 100%;
				padding: 0;
				font-size: 1.1em;
				font-family: inherit;
				background-color: transparent;
				color: rgb(255, 255, 255);
				&::-webkit-input-placeholder {
					color: rgba(255, 255, 255, 0.6) !important;
					opacity: 1;
				}
			}
		}
		.closeIcon {
			position: relative;
			flex: none;
			margin-left: 8px;
			color: white;
			transition: $transition;

			&[disabled] {
				opacity: 0;
			}

			.progress {
				color: white;
			}
		}
	}
}
