@import "styles/variables.scss";

$gap: 10px;

.flag {
	width: 24px;
	border-radius: 3px;
	margin-right: 6px;
	vertical-align: middle;
	display: inline;
	border: 1px solid $light_dark-faded-color;
	#{$dark-theme} & {
		border: 1px solid $dark_light-faded-color;
	}
}

.root {
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.select {
	width: 100%;
	border-radius: 10px;
	color: $light_regular-text-color;
	&[data-filled="true"] {
		background-color: fade-out($light_main-color, 0.9);
	}
	#{$dark-theme} & {
		color: $dark_regular-text-color;
		&[data-filled="true"] {
			background-color: fade-out($dark_regular-text-color, 0.95);
		}
	}

	.icon {
		color: $light_regular-text-color;
		#{$dark-theme} & {
			color: $dark_regular-text-color;
		}
	}

	.notchedOutline {
		border-color: $light_dark-faded-color;
		#{$dark-theme} & {
			border: 1px solid $dark_light-faded-color;
		}
	}
	&[data-filled="true"] .notchedOutline {
		border: none;
	}
}

.selectMarginBottom {
	margin-bottom: $gap;
}

.halfSelect {
	width: calc(50% - ($gap / 2));
}

.menuItem {
	white-space: normal;
	max-width: $max-width;
}
