@import "styles/variables.scss";

.languageIcon {
	position: relative;
	width: 24px;
	height: 24px;
	object-position: center;
	object-fit: cover;
	border-radius: 50%;
}

.warnBlock {
	background-color: fade-out($negative-color, .8);
	padding: 6px 8px;
	color: $negative-color;
	border-radius: 10px;
	margin-top: 4px;
	margin-bottom: 20px;

	.warnIcon {
		color: inherit;
		height: 1em;
		vertical-align: bottom;
		margin-top: -1px;
	}
}

.menuItem {
	white-space: normal;
	max-width: $max-width;
}
