@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

html,
body {
	width: 100%;
	//height: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	background-color: $light_background-color;
	font-size: 16px;
	font-family: $font-family;

	&[data-theme="light"] {
		background-color: $light_background-color;
	}
	&[data-theme="dark"] {
		background-color: $dark_background-color;
	}

	@media print {
		display: none;
		visibility: hidden;
	}
}

#root {
	position: relative;
	min-height: 100vh;
	//overflow-x: hidden;
}
